<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-alert color="accent">
            <current-plan-info />
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
    <calendesk-plans key="store-mode" store-mode />
  </div>
</template>
<script>
import CalendeskPlans from "@/views/dashboard/pages/Plans/CalendeskPlans.vue";
import CurrentPlanInfo from "@/views/dashboard/pages/Billing/components/CurrentPlanInfo.vue";

export default {
  components: { CurrentPlanInfo, CalendeskPlans },
};
</script>
